import React, { useEffect, useState } from 'react'

import { Theme } from '@platform-ui-kit/components-library'
import '@platform-ui-kit/components-library/dist/collection/global.css'
import '@platform-ui-kit/components-library/dist/platform-ui-kit/wpp-theme.css'
import { createTheme } from '@platform-ui-kit/components-library/dist/platform-ui-kit/utils/theme'
import { ComponentsPreview } from 'pages/ComponentsPreview/ComponentsPreview'
import { DataVisualizationPreview } from 'pages/DataVisualizationPreview/DataVisualizationPreview'
import { FormPreview } from 'pages/FormPreview/FormPreview'
import { HomePreview } from 'pages/HomePreview/HomePreview'
import { TablePreview } from 'pages/TablePreview/TablePreview'
import { loadStyleSheet } from 'pages/utils'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'

import './App.css'

import { THEME_BUILDER_API_URL } from './config'

const RoutesList = () => {
  const [theme, setTheme] = useState<Theme>()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const paramValue = searchParams.get('id')

  useEffect(() => {
    fetch(`${THEME_BUILDER_API_URL}/files-export/preview/${paramValue}`, {
      method: 'GET',
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Failed to load preview')
        }
      })
      .then(json => loadStyleSheet(json?.settings?.fontUrl, () => setTheme(json)))
      .catch(error => {
        console.error(error)
      })
  }, [paramValue])

  const themeStyles = theme && Object.keys(theme).length ? createTheme(theme) : {}

  return (
    <div style={themeStyles}>
      <Routes>
        <Route path="home" element={<HomePreview />} />
        <Route path="data-viz" element={<DataVisualizationPreview />} />
        <Route path="components" element={<ComponentsPreview />} />
        <Route path="table" element={<TablePreview />} />
        <Route path="form" element={<FormPreview />} />
      </Routes>
    </div>
  )
}

function App() {
  return (
    <BrowserRouter>
      <RoutesList />
    </BrowserRouter>
  )
}

export default App
